import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card mb-5 mb-xxl-8",
  id: "customers_list"
}
const _hoisted_2 = { class: "card-body pt-9 pb-0" }
const _hoisted_3 = { class: "d-flex overflow-auto h-35px" }
const _hoisted_4 = { class: "nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-6 fw-bold flex-nowrap" }
const _hoisted_5 = { class: "nav-item text-center" }
const _hoisted_6 = { class: "nav-item text-center" }
const _hoisted_7 = { class: "nav-item text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showCustomerList)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("ul", _hoisted_4, [
                _createElementVNode("li", _hoisted_5, [
                  _createVNode(_component_router_link, {
                    to: "/customers/cbs",
                    class: "nav-link text-active-primary me-6",
                    "active-class": "active"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" CBS (All) ")
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("li", _hoisted_6, [
                  _createVNode(_component_router_link, {
                    to: "/customers/cbs/urus",
                    class: "nav-link text-active-primary me-6",
                    "active-class": "active"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" CBS (URUS) ")
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("li", _hoisted_7, [
                  _createVNode(_component_router_link, {
                    to: "/customers/cbs/first",
                    class: "nav-link text-active-primary me-6",
                    "active-class": "active"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" CBS (FIRST) ")
                    ]),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ], 64))
}