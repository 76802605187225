
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "profile",
  components: {},
  data() {
    return {
      route: useRoute(),
      cstmr_id: this.$route.params.cstmr_id,
      showCustomerList: true,
    };
  },
  async mounted() {
    const pathArray = this.$route.path.split("/");
    if (pathArray[2] != "cbs") {
      this.showCustomerList = false;
    } else {
      this.showCustomerList = true;
    }
  },
});
